export default {
  enableEventListener(state) {
    state.isListenerActive = true
  },
  disableEventListener(state) {
    state.isListenerActive = false
  },
  handleContextMenuLink(state, data) {
    state.disableContextMenu = data
  },
}
