
import { mapState } from 'vuex'
import { get } from 'lodash'
import { showError } from '~/utils'
import Endpoint from '~/repository/endpoint'
import paymentV2 from '~/mixins/payment'
import axios from 'axios'

const CloseButton = () => import('~/components/Button/CloseButton.vue')

export default {
  components: {
    CloseButton,
  },
  mixins: [paymentV2],
  data() {
    return {
      // incase we want to use different title between redirecting and opening payment popup
      showRedirectingLabel: false,
      showOpeningLabel: false,
      supportedPromptpayLogo: ['scb-bank.png', 'truemoney-logo.png', 'kosikorn-bank.png', 'ktb-bank.png', 'shopeepay-logo.png'],
      qrCodeImage: null,
      qrPaymentLogo:
        {
          SG_PAYNOW_ONLINE: 'paynow.png',
          MY_DUITNOW: 'duitnow.png',
          MY_MYBANKQR: 'maybankqr.png',
          VN_VIET_QR_PAYME: 'vietqr.png',
          AU_PAY_ID: 'payid.png',
          IN_UPI_QR: 'upiqr.png',
          PH_QRPH: 'qrph.png',
          QRIS: 'qris.png',
          ELEMENTPAY: 'promptpay.png',
        },
    }
  },
  computed: {
    ...mapState({
      transaction: state => state.payment.transactionToCheck,
      promptpayData: state => state.payment.promptpayData,
    }),
    checkStatusInterval: {
      get() {
        return this.$store.state.payment.checkStatusInterval
      },
      set(val) {
        return this.$store.commit('payment/setCheckStatusInterval', val)
      },
    },
    hasPaymentLink() {
      const paymentSource = ['DRAGONPAY_GCASH', 'DRAGONPAY_PAYMAYA', 'SENANGPAY', 'PAYPAL', 'XENDIT', 'ZALOPAY', 'SIAMPAY', 'PEPAY']
      return paymentSource.includes(this.transaction.payment_src)
    },
    qrCodeLogo() {
      let logo
      if (this.transaction.payment_src === 'ELEMENTPAY') {
        logo = this.qrPaymentLogo[this.transaction.payment_src]
      } else if (['MY_DUITNOW', 'MY_MYBANKQR', 'VN_VIET_QR_PAYME', 'AU_PAY_ID', 'IN_UPI_QR', 'PH_QRPH', 'QRIS', 'MY_WECHATPAY', 'SG_PAYNOW_ONLINE'].includes(this.transaction.payment_method)) {
        logo = this.qrPaymentLogo[this.transaction.payment_method]
      }
      return logo
    },
    qrCodePayment() {
      if (this.transaction) {
        if (this.transaction.payment_src === 'HITPAY' && this.transaction.payment_method !== 'AU_PAY_ID') {
          return true
        }
        if (this.transaction.payment_src === 'XENDIT' && ['QRIS'].includes(this.transaction.payment_method)) {
          return true
        }
        if (this.transaction.payment_src === 'ELEMENTPAY') {
          return true
        }
      }
      return false
    },
  },
  watch: {
    transaction: {
      immediate: true,
      handler(value, oldValue) {
        this.clearStatusInterval()

        if (value && value !== oldValue) {
          this.checkTransaction()

          if ([
            'grabpay',
          ].includes(this.transaction.payment_src.toLowerCase())) {
            this.showRedirectingLabel = true

            setTimeout(() => {
              this.showRedirectingLabel = false
            }, 3000)
          } else if ([
            'stripe_paynow',
          ].includes(this.transaction.payment_src.toLowerCase())) {
            this.showOpeningLabel = true

            setTimeout(() => {
              this.showOpeningLabel = false
            }, 3000)
          }
        } else if (value) {
          // no transaction to check again and remove the loading
          this.closeModal()
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.qrCodePayment) {
        this.generateQRCode()
      }
    })
  },
  methods: {
    showPaymentLogo() {
      switch (this.promptpayData.paymentMethod) {
        case 'TOPUP':
          return this.$t('viewWallet')
        default:
          return this.$t('viewWallet')
      }
    },
    clearStatusInterval() {
      if (this.checkStatusInterval) {
        // TODO: should handle multiple ongoing payment
        clearInterval(this.checkStatusInterval)
        this.checkStatusInterval = null
      }
    },
    async getOrderData() {
      try {
        const { data } = await this.$apiV2.get(`/payment-order/orders/${this.$route.params.id}`)
        this.order = data
      } catch (error) {
        showError(error)
      }
    },
    async getTransactionDetail(transactionID) {
      try {
        if (transactionID.length === 36) {
          return
        }
        const authToken = await this.$auth.strategy.token.get()
        // check transaction id to differentiate recurring and one time transaction
        const url = ((transactionID.startsWith('UT') || transactionID.startsWith('UM')) && !transactionID.startsWith('UTO') && !transactionID.startsWith('UMO'))
          ? `${Endpoint.detailRecurringV2}?sub_human_id=${transactionID}`
          : `${Endpoint.detailTransactionV2}?human_id=${transactionID}`
        const { data } = await axios.get(url,
          {
            baseURL: Endpoint.apiUrl,
            headers: authToken && !window.location.href.includes('checkout') ? { Authorization: authToken } : {},
          })
        this.$store.commit('payment/setTransactionToCheck', data)
      } catch (error) {
        showError(error, 'Get user transactions failed!')
      }
    },
    checkTransaction() {
      // prevent checking for v1 ID to be checked
      if ((this.transaction && this.transaction.human_id && this.transaction.human_id.length === 36) || (this.transaction && this.transaction.sub_human_id && this.transaction.sub_human_id.length === 36)) {
        return undefined
      }
      const donor = this.$cookies.get('success-donor')
      if (this.transaction && this.transaction.payment_status === 'SUCCEED') {
        if (this.$auth.loggedIn && (this.transaction.usecase === 'DIGITAL_GOODS' || this.transaction.usecase === 'MERCHANDISE' || this.transaction.usecase === 'SERVICE')) {
          this.getOrderData()
        }
        return undefined
      }
      return new Promise((resolve, reject) => {
        // Stop previous status checking worker before starting a new one
        if (this.checkStatusInterval) {
          this.clearStatusInterval()
        }

        this.checkStatusInterval = setInterval(
          async () => {
            try {
              if (this.transaction) {
                await this.getTransactionDetail(this.transaction.human_id || this.transaction.sub_human_id)
              }
              const status = get(this.transaction, 'payment_status')
              const subStatus = get(this.transaction, 'active', false)
              if (status === 'SUCCEED') {
                if (donor && this.transaction.usecase === 'DONATION') {
                  this.$cookies.set('success-donation', true)
                }
                if (this.$auth.loggedIn && (this.transaction.usecase === 'DIGITAL_GOODS' || this.transaction.usecase === 'MERCHANDISE' || this.transaction.usecase === 'SERVICE') && this.transaction.payment_status === 'SUCCEED') {
                  this.getOrderData()
                }

                // remove transactionID from cookies
                this.$cookies.remove(this.transaction.human_id)

                this.clearStatusInterval()

                // no transaction to check again and remove the loading
                this.closeModal()
                resolve(status)
              }
              if (status === 'FAILED') {
                this.clearStatusInterval()

                // no transaction to check again and remove the loading
                this.closeModal()
                resolve(status)
              }
              if (subStatus) {
                this.clearStatusInterval()

                // no transaction to check again and remove the loading
                this.closeModal()
                resolve(status)
              }
            } catch (error) {
              this.clearStatusInterval()

              // no transaction to check again and remove the loading
              this.closeModal()
              reject(error)
            }
          },
          3000,
        )

        setTimeout(() => {
          this.clearStatusInterval()

          // no transaction to check again and remove the loading
          this.closeModal()
        }, 600000) // 1000 * 60 * 10 = 10 minutes
      })
    },
    closeModal() {
      this.$store.commit('payment/setPromptpayData', null)
      this.$store.commit('payment/setTransactionToCheck', null)
    },
    openLink() {
      const linkPayment = ['DRAGONPAY_GCASH', 'DRAGONPAY_PAYMAYA', 'SENANGPAY', 'PAYPAL', 'XENDIT', 'ZALOPAY', 'SIAMPAY']
      if (this.transaction.payment_src === 'PEPAY') {
        this.processPepayPayment(this.transaction.payment_query, this.transaction.payment_method)
      }
      if (linkPayment.includes(this.transaction.payment_src) && this.transaction.payment_method !== 'QRIS') {
        const win = window.open(this.transaction.payment_url)
        localStorage.setItem('popup', true)
        if (!win) {
          localStorage.setItem('popup', false)
          window.location.assign(this.transaction.payment_url)
        }
      }
    },
    async generateQRCode() {
      const image = this.promptpayData?.data?.payment_provider_data.url || this.promptpayData?.data?.payment_provider_data.qr || this.promptpayData?.paymentQrCode
      if (this.transaction.payment_src === 'HITPAY' || (this.transaction.payment_src === 'XENDIT' && ['QRIS'].includes(this.transaction.payment_method))) {
        await this.$nextTick()
        const { canvas } = this.$refs
        const QRCode = await import('qrcode')
        await QRCode.toCanvas(canvas, image, { width: 200, errorCorrectionLevel: 'H' })

        setTimeout(() => {
          this.qrCodeImage = canvas.toDataURL('image/png')
        }, 100)
      } else {
        this.qrCodeImage = image
      }
    },
  },
}
