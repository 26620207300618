import currency from '../../assets/json/currency.json'

const getters = {
  getUserCurrency: state => {
    const eligiblePaymentCountry = ['ID', 'MY', 'SG', 'TH', 'PH', 'VN', 'TW', 'AU', 'IN']
    const isEligibleCountry = eligiblePaymentCountry.includes(state.countryUser)
    const country = isEligibleCountry ? state.countryUser : 'ALL'
    const obj = currency.find(pm => pm.country === country)
    return obj?.code || 'USD'
  },
  getUserWithdrawalCurrency: state => {
    const obj = currency.find(pm => pm.country === state.countryUser)
    return obj?.code || 'USD'
  },
}

export default getters
