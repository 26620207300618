
const SignUpUserModal = () => import('~/components/Modal/SignUpUserModal/index.vue')
const PaymentLoading = () => import('~/components/PaymentLoading/index.vue')

export default {
  components: {
    SignUpUserModal,
    PaymentLoading,
  },
  data() {
    return {
      showPopup: false,
    }
  },
  created() {
    const { context } = this.$nuxt
    const currentPath = this.$route.path
    const isIncluded = currentPath.includes('/signup') || currentPath.includes('/login') || currentPath.includes('/checkout')
    const isConfirmationPage = this.$route.name === 'confirmation'
    if (context.$device.isMobileOrTablet && !isIncluded && !isConfirmationPage) {
      context.redirect('/download')
    }
  },
  mounted() {
    const isNewUser = this.$auth.$storage.getUniversal('isNewUser')
    if (this.$auth.loggedIn && isNewUser) {
      this.showPopup = true
      this.$auth.$storage.removeUniversal('isNewUser')
    }
  },
}
