import get from 'lodash/get'
import { PAYMENT_METHOD_TYPES } from '~/utils'

const getters = {
  /**
   *
   * @param {*} state
   * @returns stripe account's default payment method
   */
  getDefaultPaymentMethod: state => get(state, 'stripeAccount.defaultPaymentMethod'),
  /**
   *
   * @param {*} state
   * @returns current payment method item
   */
  getCurrentPaymentMethodItem: state => {
    const paymentMethodId = state.defaultPaymentMethodId
    const paymentMethodType = state.defaultPaymentMethodType
    const specificPaymentMethods = [
      PAYMENT_METHOD_TYPES.GRABPAY,
      PAYMENT_METHOD_TYPES.STRIPE_WECHAT_PAY,
      PAYMENT_METHOD_TYPES.STRIPE_PAYNOW,
      PAYMENT_METHOD_TYPES.DRAGONPAY_GCASH,
      PAYMENT_METHOD_TYPES.DRAGONPAY_PAYMAYA,
      PAYMENT_METHOD_TYPES.EARNINGS,
      PAYMENT_METHOD_TYPES.GCASH,
      PAYMENT_METHOD_TYPES.PAYPAL,
      PAYMENT_METHOD_TYPES.SENANGPAY,
      PAYMENT_METHOD_TYPES.IPAYMU,
      PAYMENT_METHOD_TYPES.XENDIT,
      PAYMENT_METHOD_TYPES.HITPAY_SG_PAYNOW_ONLINE,
      PAYMENT_METHOD_TYPES.HITPAY_MY_DUITNOW,
      PAYMENT_METHOD_TYPES.HITPAY_MY_MYBANK_QR,
      PAYMENT_METHOD_TYPES.HITPAY_AU_PAY_ID,
      PAYMENT_METHOD_TYPES.HITPAY_IN_UPI_QR,
      PAYMENT_METHOD_TYPES.HITPAY_VN_VIET_QR_PAYME,
      PAYMENT_METHOD_TYPES.HITPAY_PH_QRPH,
      PAYMENT_METHOD_TYPES.ZALOPAY_WALLET,
      PAYMENT_METHOD_TYPES.ZALOPAY_VIETQR,
      PAYMENT_METHOD_TYPES.ZALOPAY_LOCALBANK,
      PAYMENT_METHOD_TYPES.PEPAY_LINEPAY,
      PAYMENT_METHOD_TYPES.PEPAY_JKO,
      PAYMENT_METHOD_TYPES.PEPAY_CONVENIENCE_STORE_FAMI,
      PAYMENT_METHOD_TYPES.PEPAY_CONVENIENCE_STORE_IBON,
      PAYMENT_METHOD_TYPES.PEPAY_CONVENIENCE_STORE_MBC,
      PAYMENT_METHOD_TYPES.PEPAY_ATM_CTCB,
      PAYMENT_METHOD_TYPES.PEPAY_ATM_HNCB,
      PAYMENT_METHOD_TYPES.PEPAY_ATM_SCSB,
      PAYMENT_METHOD_TYPES.XENDIT_CREDIT_CARD,
      PAYMENT_METHOD_TYPES.XENDIT_INTERNET_BANKING,
      PAYMENT_METHOD_TYPES.XENDIT_DANA,
      PAYMENT_METHOD_TYPES.XENDIT_OVO,
      PAYMENT_METHOD_TYPES.XENDIT_QRIS,
      PAYMENT_METHOD_TYPES.XENDIT_MY_WECHATPAY,
      PAYMENT_METHOD_TYPES.SIAMPAY_QR_PROMPTPAY,
      PAYMENT_METHOD_TYPES.SIAMPAY_TRUEMONEY,
      PAYMENT_METHOD_TYPES.STRIPE_GOOGLE_PLAY,
      PAYMENT_METHOD_TYPES.STRIPE_APPLEPAY,
      PAYMENT_METHOD_TYPES.ELEMENTPAY,
    ]

    if (specificPaymentMethods.includes(paymentMethodType)) {
      return { id: null, paymentMethodType }
    }

    const obj = state.paymentMethods.find(pm => pm.id === paymentMethodId)
    return obj ? { ...obj, paymentMethodType } : undefined
  },
  /**
   *
   * @param {*} state
   * @returns API key that's used to authenticate via bearer auth
   */
  getEphemeralKey: state => get(state, 'ephemeralData.secret'),
  /**
   *
   * @param {*} state
   * @returns stripe's customer id
   */
  getStripeAccountID: state => get(state, 'stripeAccount.stripeAccountID'),
  getPaymentConfig: state => get(state, 'paymentConfig'),
  getTopupConfig: state => get(state, 'topupConfig'),
  getWithdrawalConfig: state => get(state, 'withdrawalConfig'),
}

export default getters
