
import get from 'lodash/get'
import LayoutHeader from '~/components/Layouts/MainHeader.vue'

const SignUpUserModal = () => import('~/components/Modal/SignUpUserModal/index.vue')
const PaymentLoading = () => import('~/components/PaymentLoading/index.vue')

export default {
  components: {
    LayoutHeader,
    SignUpUserModal,
    PaymentLoading,
  },
  data() {
    return {
      showPopup: false,
    }
  },
  computed: {
    userId() {
      return get(this.$auth, 'user.id') || ''
    },
    userRoles() {
      return get(this.$auth, 'user.userType') || ''
    },
  },
  created() {
    const { context } = this.$nuxt
    const isConfirmationPage = this.$route.name === 'confirmation'
    if (context.$device.isMobileOrTablet && !isConfirmationPage) {
      context.redirect('/download')
    }
  },
  mounted() {
    if (this.userId) {
      this.$gtag.config({ user_id: this.userId })
      this.$gtm.push({ event: 'userIdSet', user_id: this.userId, roles: this.userRoles })
    }

    const isNewUser = this.$auth.$storage.getUniversal('isNewUser')
    if (this.$auth.loggedIn && isNewUser) {
      this.showPopup = true
      this.$auth.$storage.removeUniversal('isNewUser')
    }
  },
}
